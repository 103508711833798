
import Vue from "vue";
import { isEmpty } from "lodash";
// @ts-ignore
import Filterable from "@/components/Header/Tables/Filterable.vue";
// @ts-ignore
import {
	isRequired,
	isNumber,
	isMin,
	isNotZero,
	// @ts-ignore
} from "@/services/rule-services";
// @ts-ignore
import { totalVisiblePagination } from "@/utils/services-global";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import { mapGetters } from "vuex";
//@ts-ignore
import { getPermisionCustomList } from "@/utils/permissionResolve";

export default Vue.extend({
	name: "CustomListItemTable",
	model: {
		prop: "model_data",
		event: "change",
	},
	props: {
		model_data: {},
		custom_list: { type: Object, default: () => ({}) },
		headers: { type: Array, default: () => [] },
		items: { type: Array, default: () => [] },
		option: { type: Object, default: () => ({}) },
		filters: { type: Object, default: () => ({}) },
		current_page: { type: Number },
		next_page_url: { type: String },
		path: { type: String },
		per_page: { type: Number },
		prev_page_url: { type: String },
		to: { type: Number },
		total: { type: Number },
		exchanges: { type: Array },
	},
	components: { Filterable, IconBase },

	data: () => ({
		input_mode: "manual",
		current_file: undefined,
		progress: 0,
	}),

	created() { },

	async mounted() { },

	computed: {
		...mapGetters("profile", ["getAbility"]),
		item: {
			set(val: any) {
				this.$emit("change", val);
			},
			get() {
				return this.model_data;
			},
		},

		currentPage: {
			set(val) {
				this.$emit("update-current-page", val);
			},
			get() {
				return this.current_page;
			},
		},
		getLength() {
			return Math.ceil(this.total / this.per_page);
		},
		perPage() {
			return !isNaN(this.per_page) && isEmpty(this.per_page)
				? this.per_page
				: 25;
		},
		getTotalVisible() {
			return totalVisiblePagination;
		},
		getRules() {
			return { isRequired, isNumber, isMin, isNotZero };
		},
		hasItem(){
			return this.items.length > 0;
		},

		canEdit(){
			return this.getAbility.can(this.getPermission.actions.update_item, this.getPermission.subject);
		},

		canDelete(){
			return this.getAbility.can(this.getPermission.actions.delete_item, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionCustomList();
		}
	},

	methods: {
		handleAddItem() {
			this.$emit("add-item", {});
		},
		async handleEdit(index: any) {
			this.$emit("edit-item", index);
		},
		async handleDelete(index: any) {
			this.$emit("delete-item", index);
		},
		async handleDeleteAll() {
			this.$emit("delete-all-item");
		},
	},

	// watch: {
	// 	items: {
	// 		deep: true,
	// 		handler() {
	// 			console.log("watch:items", { items: this.items });
	// 		},
	// 	},
	// },
});
