
import Vue from "vue";
//@ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
//@ts-ignore
import { ResultPaginate } from "@/interfaces/user";
//@ts-ignore
import ParamService from "@/services/params-service";
import { isNull, isUndefined } from "lodash";
//@ts-ignore
import CustomListItemTable from "@/views/Admin/CustomListItems/CustomListItemTable.vue";
//@ts-ignore
import notificationService from "@/services/notification-service";
//@ts-ignore
import { MessageTypes, Notification } from "@/interfaces/proccess";
//@ts-ignore
import i18n from "@/plugins/i18n";
//@ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
//@ts-ignore
import RadioButton from "@/components/Content/RadioButton.vue";
//@ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions, mapGetters } from "vuex";
//@ts-ignore
import { getPermisionCustomList } from "@/utils/permissionResolve";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "CustomListItems",
	props: {
		custom_list: { required: true },
	},
	components: { 
		CustomListItemTable, 
		CardActions, 
		RadioButton, 
		NotPermission 
	},
	data: () => ({
		page: {},
		input_mode: "manual",
		headers: [],
		filters: {},
		paginated: { page: 1, limit: 10 } as Paginated,
		option: { sort: "id", order: "desc" } as SortingOption,
		current_file: undefined,
		upload_progress: 0,
		filename: undefined,
		exchanges: [],
		prefix_types: [
			"app_id",
			"deal_id",
			"placement_id",
			"publisher_id",
			"site_id",
		],
		modes: [],
		bgColor: "#ffffff",

		items: [] as any[]
	}),
	created() {
		this.modes = [
			{
				id: "manual",
				value: "customListItem.labels.manualInput",
			},
			{
				id: "upload",
				value: "customListItem.labels.uploadInput",
			},
		];
		this.$nextTick(async function () {
			await this.loadData();
		});
	},
	mounted() { },
	computed: {
		...mapGetters("profile", ["getAbility"]),
		getPaginatedResult(): ResultPaginate {
			return this.page;
		},
		getCustomListItems() {
			const result: ResultPaginate = this.getPaginatedResult;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},
		getExchanges() {
			return this.exchanges;
		},
		prepareTableHeaders() {

			const items = this.getCustomListItems;

			if (isUndefined(this.custom_list) || isUndefined(items) || isNull(items))
				return [];

			const headers = [
				{
					text: "ID",
					align: "left",
					value: "id",
					sortable: false,
					filterable: false,
				},
				{
					text: this.getLabelRequired("latitude"),
					align: "left",
					value: "lat",
					sortable: false,
					filterable: false,
				},
				{
					text: this.getLabelRequired("longitude"),
					align: "left",
					value: "long",
					sortable: false,
					filterable: false,
				},
				{
					text: this.getLabelRequired("radius_km"),
					align: "left",
					value: "radius_km",
					sortable: false,
					filterable: false,
				},
				{
					text: this.getLabelRequired("prefix"),
					align: "left",
					value: "prefix",
					sortable: false,
					filterable: false,
				},
				{
					text: this.getLabelRequired("list_item"),
					align: "left",
					value: "list_item",
					sortable: false,
					filterable: false,
				},
				{
					text: i18n.t("customListItem.fields.value"),
					align: "left",
					value: "value",
					sortable: false,
					filterable: false,
				},
				{
					text: this.getLabelRequired("name"),
					align: "left",
					value: "name",
					sortable: false,
					filterable: false,
				},
				{
					text: "",
					align: "left",
					sortable: false,
					value: "actions",
					width: "5%",
				},
			];

			const type = this.custom_list?.type?.key;

			// si es tipo lat_long
			if (type === "lat_long") {
				const cols = [
					"id",
					"lat",
					"long",
					"radius_km",
					"value",
					"name",
					"actions",
				];
				return headers.filter((item) => cols.indexOf(item.value) != -1);
			}
			// si el tipo esta entre los prefix_types
			else if (this.prefix_types.indexOf(type) != -1) {
				const cols = ["id", "prefix", "list_item", "value", "name", "actions"];
				return headers.filter((item) => cols.indexOf(item.value) != -1);
			} else {
				const cols = ["id", "list_item", "value", "name", "actions"];
				return headers.filter((item) => cols.indexOf(item.value) != -1);
			}
		},
		prepareTableContent() {
			const items = this.getCustomListItems;

			if (isUndefined(this.custom_list) || isUndefined(items) || isNull(items))
				return [];

			const type = this.custom_list?.type?.key;
			const table_content = items.map((item) => {
				if (type == "lat_long" && item.list_item != "") {
					item.lat = item.list_item.lat;
					item.long = item.list_item.long;
					item.radius_km = item.list_item.radius_km;
					return item;
				} else if (this.prefix_types.indexOf(type) != -1 && item.list_item != "") {
					const parts = item.list_item.split("/");
					if (parts.length == 2) {
						item.prefix = parts[0];
						item.list_item = parts[1];
					}
				}
				return item;
			});

			return table_content;
		},
		stylesDrag() {
			return { "background-color": this.bgColor };
		},

		canIndex(){
			return this.getAbility.can(this.getPermission.actions.index_item, this.getPermission.subject);
		},

		canCreate(){
			return this.getAbility.can(this.getPermission.actions.create_item, this.getPermission.subject);
		},

		canEdit(){
			return this.getAbility.can(this.getPermission.actions.update_item, this.getPermission.subject);
		},

		canDelete(){
			return this.getAbility.can(this.getPermission.actions.delete_item, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionCustomList();
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		async loadData(){
			this.setLoadingData(TypeLoading.loading)
			await this.loadExchanges();
			this.page = await this.getPaginated();
			await this.setLoadingData();
		},

		async getPaginated() {
			this.filters.custom_list_id = this.custom_list.id;
			const params = await ParamService.getParams(
				this.paginated,
				this.filters,
				this.option
			);
			return await this.$store.dispatch("list_item/paginated", params, {
				root: true,
			});
		},
		async deleteCustomListItem(id: any) {
			return await this.$store.dispatch("list_item/delete", id, {
				root: true,
			});
		},
		async deleteAllCustomListItem(id: any) {
			return await this.$store.dispatch("list_item/deleteAll", id, {
				root: true,
			});
		},
		handleInputTypeChanged(v: any) {
			console.log("handleInputTypeChanged", v);
		},
		handleAddItem() {
			this.page.data.unshift({
				id: undefined,
				value: "",
				name: "",
				editing: true,
				lat: undefined,
				long: undefined,
				radius_km: this.custom_list.default_radius_km
					? this.custom_list.default_radius_km
					: undefined,
				list_item: "",
				prefix: undefined,
			});
		},
		async handleEditItem(index: any) {
			const item = this.page.data[index];
			this.page.data.splice(index, 1, { ...item, editing: true });
		},
		async handleDeleteItem(index: any) {
			let item = this.page.data[index];
			if (!!item.id) {
				delete item.editing;
				this.page.data.splice(index, 1, { ...item, deleted: true });
			} else {
				this.page.data.splice(index, 1);
			}
		},
		// Elimina todos los items de la lista si esta activa
		async handleDeleteAllItem(params: any) {
			if (this.custom_list.active)  {
				await this.setLoadingData(TypeLoading.loading);
				await this.deleteAllCustomListItem(this.custom_list.id)
				.then((response) => {
					this.setLoadingData();
				})
				.catch(err => {
					this.setLoadingData();
				});
				this.$store.dispatch(
					"proccess/setNotification",
					await notificationService.CreateNotification({
						type: MessageTypes.SUCCESS,
						title: this.$t("title-success"),
						message: this.$t('customListItem.messages.delete-all-list'),
						btn_text: this.$t("continue"),
					} as Notification)
				);
				await this.loadData();
			} else {
				this.$store.dispatch(
					"proccess/setNotification",
					await notificationService.CreateNotification({
						type: MessageTypes.ERROR,
						title: "Error",
						message: this.$t('customListItem.messages.error-delete-all'),
						btn_text: this.$t("try-again"),
					} as Notification)
				);
			}
		},
		getLabelRequired(field: string) {
			const text: string = `customListItem.fields.${field}`;
			return `${i18n.t(
				text
			)} <span class="red--text"><strong>*</strong></span>`;
		},

		/**
		 * From CardActions - line item
		 * @param action
		 */
		async handleAction(action: { type: any }) {
			switch (action.type) {
				case "save":
					await this.handleSubmit({ continue: false });
					break;

				case "save-continue":
					await this.handleSubmit({ continue: true });
					break;

				case "cancel":
					this.$emit("cancel");
					break;
			}
		},

		async handleSubmit() {
			// edited
			const edited = this.page.data
				.filter((item: any) => !!item.id && !!item.editing)
				.map(({ id, value, name }) => ({ id, value, name }));

			// deleted
			const deleted = this.page.data
				.filter((item: any) => !!item.deleted)
				.map(({ id }) => ({ id }));

			// created
			const created = this.page.data
				.filter((item: any) => !item.id)
				.map((item: any) => {
					const type: string = this.custom_list?.type?.key;
					if (type == "lat_long") {
						const { lat, long, radius_km } = item;
						item = {
							...item,
							list_item: {
								lat: Number(lat),
								long: Number(long),
								radius_km: Number(radius_km),
							},
						};
					} else if (this.prefix_types.indexOf(type) > -1) {
						item.list_item = `${item.prefix}/${item.list_item}`;
					}
					return {
						...item,
						custom_list_id: this.custom_list.id,
					};
				});

			this.$emit("save", {
				edited,
				deleted,
				created,
				done: async () => {
					this.page = await this.getPaginated();
				},
			});
		},
		handleCancel() {
			this.$emit("cancel", {});
		},
		async handleUpdateCurrentPage(page) {
			const edited = this.page.data.filter((item: any) => !!item.id && item.editing).length > 0;
			const deleted = this.page.data.filter((item: any) => item.deleted).length > 0;
			const created = this.page.data.filter((item: any) => !!!item.id).length > 0;

			if (edited || deleted || created) {
				this.$store.dispatch(
					"proccess/setNotification",
					await notificationService.CreateNotification({
						type: MessageTypes.ERROR,
						title: "Error",
						message: i18n.t("customListItem.labels.paginationError"),
						btn_text: i18n.t(MessageTypes.CONTINUE),
					} as Notification)
				);
				return;
			}

			this.setLoadingData(TypeLoading.loading)
			this.paginated.page = page;
			this.page = await this.getPaginated();
			await this.setLoadingData();
		},
		handleSelectFile(file: any) {
			this.current_file = file;
			this.upload_progress = 0;
		},
		onUploadProgress(event: any) {
			this.upload_progress = Math.round((100 * event.loaded) / event.total);
		},
		async handleUpload() {
			if (!this.current_file || this.upload_progress != 0) return;
			this.setLoadingData(TypeLoading.loading)
			try {
				await this.$store.dispatch(
					"custom_list/uploadItems",
					{
						payload: {
							custom_list_id: this.custom_list.id,
							file: this.current_file,
						},
						function: this.onUploadProgress,
					},
					{ root: true }
				);
				this.page = await this.getPaginated();
				await this.setLoadingData();
				this.filename = undefined;
				this.current_file = undefined;
				this.upload_progress = 0;
				this.$router.push({ name: "CustomListIndex" });
			} catch (error) {
				console.error("handleUpload", error);
				await this.setLoadingData();
				this.filename = undefined;
				this.current_file = undefined;
				this.upload_progress = 0;
			}
		},

		dropHandler(event) {
			this.bgColor = "#fff";
			const fileAccept = ["application/vnd.ms-excel", "text/plain"];
			if (event) {
				let file = event.dataTransfer.files[0];
				if (file) {
					if (fileAccept.includes(String(file.type))) {
						this.filename = file;
						this.handleSelectFile(file);
					}
				}
			}
		},

		dragOverHandler(event) {
			if (event) {
				this.bgColor = "#eee";
				this.input_mode = "upload";
			}
		},

		dragLeaveHandle(event) {
			if (event) {
				this.bgColor = "#fff";
			}
		},

		/*
		 * Carga la lista de exchanges
		 */

		async loadExchanges() {
			this.exchanges = await this.$store.dispatch(
				"lists/getList",
				{ list_name: "custom_list_exchanges" },
				{ root: true }
			);
		},

		handleChangeMode(params) {
			console.log("handleChangeMode", { params });
		},
	},
});
